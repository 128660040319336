import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { App as AntApp } from "antd";
import SuspenseContent from "./containers/SuspenseContent";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <Suspense fallback={<SuspenseContent />}>
            <Provider store={store}>
                <ConfigProvider theme={{ token: { colorPrimary: "#bf0dc3" } }}>
                    <AntApp>
                        <App />
                    </AntApp>
                </ConfigProvider>
            </Provider>
        </Suspense>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
