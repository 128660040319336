enum ModalTypes {
    CONFIRMATION = 1,
    VERIFY_EMAIL,
    DEFAULT,
}

const CONSTANTS = Object.freeze({
    RIGHT_DRAWER_TYPES: {
        NOTIFICATION: "NOTIFICATION",
        CALENDAR_EVENTS: "CALENDAR_EVENTS",
    },

    CONFIRMATION_MODAL_CLOSE_TYPES: {
        CONFIRM_AND_NAVIGATE: "CONFIRM_AND_NAVIGATE",
    },

    COLORS: {
        YELLOW: "#FFC700",
        ORANGE: "#CA5805",
        PURPLE: "#BF0DC3",
        RED: "#FF471E",
    },
});

const AUTH_ERRORS: Map<string, string> = new Map<string, string>([
    ["auth/invalid-credential", "kunne ikke logge ind med de oplysninger."],
    ["auth/wrong-password", "kunne ikke logge ind med de oplysninger."],
    [
        "auth/claims-too-large",
        "Det var lidt for voldsomt. Prøv med mindre tekst.",
    ],
    [
        "auth/email-already-in-use",
        "Emailen eksisterer allerede i vores katalog. Prøv at logge ind (eller finde glemt password).",
    ],
    [
        "auth/email-already-exists",
        "Emailen eksisterer allerede i vores database. Prøv at logge ind (eller finde glemt password).",
    ],
    ["auth/id-token-expired", "Din session er udløbet. Prøv at logge ind igen."],
    ["auth/id-token-revoked", "Du har ikke længere adgang."],
    // [
    //   "auth/insufficient-permission",
    //   "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.",
    // ],
    // [
    //   "auth/internal-error",
    //   "The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.",
    // ],
    // [
    //   "auth/invalid-argument",
    //   "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
    // ],
    // [
    //   "auth/invalid-claims",
    //   "The custom claim attributes provided to setCustomUserClaims() are invalid.",
    // ],
    // ["auth/invalid-continue-uri", "The continue URL must be a valid URL string."],
    // [
    //   "auth/invalid-creation-time",
    //   "The creation time must be a valid UTC date string.",
    // ],
    [
        "auth/invalid-credential",
        "Du har brugt forkert email/password til denne resource.",
    ],
    // [
    //   "auth/invalid-disabled-field",
    //   "The provided value for the disabled user property is invalid. It must be a boolean.",
    // ],
    [
        "auth/invalid-display-name",
        "Det navn, som du har opgivet er ugyldigt. Der skal være mindst en karakter.",
    ],
    // [
    //   "auth/invalid-dynamic-link-domain",
    //   "The provided dynamic link domain is not configured or authorized for the current project.",
    // ],
    ["auth/invalid-email", "Woops, det var vidst ikke en gyldig email."],
    // [
    //   "auth/invalid-email-verified",
    //   "The provided value for the emailVerified user property is invalid. It must be a boolean.",
    // ],
    // [
    //   "auth/invalid-hash-algorithm",
    //   "The hash algorithm must match one of the strings in the list of supported algorithms.",
    // ],
    // [
    //   "auth/invalid-hash-block-size",
    //   "The hash block size must be a valid number.",
    // ],
    // [
    //   "auth/invalid-hash-derived-key-length",
    //   "The hash derived key length must be a valid number.",
    // ],
    // ["auth/invalid-hash-key", "The hash key must a valid byte buffer."],
    // [
    //   "auth/invalid-hash-memory-cost",
    //   "The hash memory cost must be a valid number.",
    // ],
    // [
    //   "auth/invalid-hash-parallelization",
    //   "The hash parallelization must be a valid number.",
    // ],
    // ["auth/invalid-hash-rounds", "The hash rounds must be a valid number."],
    // [
    //   "auth/invalid-hash-salt-separator",
    //   "The hashing algorithm salt separator field must be a valid byte buffer.",
    // ],
    // [
    //   "auth/invalid-id-token",
    //   "The provided ID token is not a valid Firebase ID token.",
    // ],
    // [
    //   "auth/invalid-last-sign-in-time",
    //   "The last sign-in time must be a valid UTC date string.",
    // ],
    // [
    //   "auth/invalid-page-token",
    //   "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.",
    // ],
    [
        "auth/invalid-password",
        "Stop lige en halv. Sikkerhed frem for alt! Din kode skal indeholde mindst 6 tegn.",
    ],
    // [
    //   "auth/invalid-password-hash",
    //   "The password hash must be a valid byte buffer.",
    // ],
    // [
    //   "auth/invalid-password-salt",
    //   "The password salt must be a valid byte buffer",
    // ],
    // [
    //   "auth/invalid-phone-number",
    //   "The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.",
    // ],
    // [
    //   "auth/invalid-photo-url",
    //   "The provided value for the photoURL user property is invalid. It must be a string URL.",
    // ],
    // [
    //   "auth/invalid-provider-data",
    //   "The providerData must be a valid array of UserInfo objects.",
    // ],
    // [
    //   "auth/invalid-provider-id",
    //   "The providerId must be a valid supported provider identifier string.",
    // ],
    // [
    //   "auth/invalid-oauth-responsetype",
    //   "Only exactly one OAuth responseType should be set to true.",
    // ],
    // [
    //   "auth/invalid-session-cookie-duration",
    //   "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
    // ],
    // [
    //   "auth/invalid-uid",
    //   "The provided uid must be a non-empty string with at most 128 characters.",
    // ],
    // ["auth/invalid-user-import", "The user record to import is invalid."],
    [
        "auth/maximum-user-count-exceeded",
        "Vi belkager! Vi kan ikke følge med efterspørgslen. Kontakt venligts support og bed om en konto.",
    ],
    // [
    //   "auth/missing-android-pkg-name",
    //   "An Android Package Name must be provided if the Android App is required to be installed.",
    // ],
    // [
    //   "auth/missing-continue-uri",
    //   "A valid continue URL must be provided in the request.",
    // ],
    // [
    //   "auth/missing-hash-algorithm",
    //   "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.",
    // ],
    // ["auth/missing-ios-bundle-id", "The request is missing a Bundle ID."],
    // [
    //   "auth/missing-uid",
    //   "A uid identifier is required for the current operation.",
    // ],
    // [
    //   "auth/missing-oauth-client-secret",
    //   "The OAuth configuration client secret is required to enable OIDC code flow.",
    // ],
    // [
    //   "auth/operation-not-allowed",
    //   "The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.",
    // ],
    [
        "auth/phone-number-already-exists",
        "Det telefonnummer kender vi allerede. Brug et andet nummer eller log ind med din eksisterende konto.",
    ],
    // [
    //   "auth/project-not-found",
    //   "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.",
    // ],
    // [
    //   "auth/reserved-claims",
    //   "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.",
    // ],
    // [
    //   "auth/session-cookie-expired",
    //   "The provided Firebase session cookie is expired.",
    // ],
    // [
    //   "auth/session-cookie-revoked",
    //   "The Firebase session cookie has been revoked.",
    // ],
    // [
    //   "auth/too-many-requests",
    //   "The number of requests exceeds the maximum allowed.",
    // ],
    // [
    //   "auth/uid-already-exists",
    //   "The provided uid is already in use by an existing user. Each user must have a unique uid.",
    // ],
    // [
    //   "auth/unauthorized-continue-uri",
    //   "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.",
    // ],
    ["auth/user-not-found", "Vi kunne ikke logge den givende bruger ind."],
]);

export { CONSTANTS, AUTH_ERRORS, ModalTypes };
