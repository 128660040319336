import { lazy, useEffect } from "react";
import "./App.css";
import {
    RouterProvider,
    Navigate,
    createBrowserRouter,
} from "react-router-dom";
import { themeChange } from "theme-change";
import initializeApp from "./app/init";
import { useAuthState } from "react-firebase-hooks/auth";
import SuspenseContent from "./containers/SuspenseContent";

import { auth } from "./app/firebase";
import { fetchCurrentUser } from "./features/common/userSlice";
import { useAppDispatch } from "./app/hooks";
import routes from "./routes";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "./firestore";
import { doc } from "firebase/firestore";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Verify = lazy(() => import("./pages/Verify"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

const PUBLIC_ROUTES = [
    "login",
    "forgot-password",
    "register",
    "documentation",
    "verify",
];

function App() {
    const dispatch = useAppDispatch();
    const [user, loadingAuth, error] = useAuthState(auth);
    const [dbUser] = useDocumentData(doc(db.users, user?.uid || "empty"));

    useEffect(() => {
        if (dbUser === undefined || !user) {
            return;
        }
        console.log({ user, dbUser, message: "Loading user" });
        dispatch(fetchCurrentUser(user.uid));
    }, [dbUser]);

    useEffect(() => {
        // 👆 daisy UI themes initialization
        themeChange(false);
    }, []);

    useEffect(() => {
        const isPublicPage = PUBLIC_ROUTES.some((r) =>
            window.location.href.includes(r),
        );
        if (isPublicPage || loadingAuth) return;

        if (user === undefined || user === null) {
            window.location.href = "/login";
        } else if (user !== undefined) {
            dispatch(fetchCurrentUser(user.uid));
        }
    }, [user, loadingAuth]);

    useEffect(() => {
        if (error !== undefined && Object.keys(error).length > 0) {
            window.location.href = "/404";
        }
    }, [error]);

    const router = createBrowserRouter([
        { path: "/login", element: <Login /> },
        { path: "/forgot-password", element: <ForgotPassword /> },
        { path: "/register", element: <Register /> },
        { path: "/documentation", element: <Documentation /> },
        { path: "/verify", element: <Verify /> },
        {
            path: "/app",
            element: <Layout />,
            children: routes.map((route) => {
                return { path: `/app${route.path}`, element: <route.component /> };
            }),
        },
        {
            path: "*",
            element: <Navigate to={!!user ? "/app/welcome" : "/login"} />,
        },
    ]);

    return (
        <>
            {loadingAuth ? <SuspenseContent /> : <RouterProvider router={router} />}
        </>
    );
}

export default App;
