import {
    QueryDocumentSnapshot,
    Timestamp,
    DocumentReference,
    WithFieldValue,
    collection,
    FieldValue,
    SnapshotOptions,
    serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../app/firebase";

const DUMMY_DATE = new Date("January 1, 1900 01:00:00");

export interface UserMark {
    uid: string;
    displayName: string;
    initials: string;
    photoUrl?: string;
}

export interface ProjectMark {
    uid: string;
    title: string;
    street: string;
    city: string;
    zip: number;
}

const name_regex = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
export const getInitials = (displayName: string) => {
    const initials = [...displayName.matchAll(name_regex)] || [];
    return (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
};

export const makeMark = (userInfo: UserDbModel | UserModel): UserMark => {
    const { uid, displayName, photoUrl } = userInfo;
    const initials = getInitials(displayName);
    return { uid, displayName, initials, photoUrl };
};

export type UserModel = {
    uid: string;
    displayName: string;
    email: string;
    photoUrl?: string;
    firstName?: string;
    lastName?: string;
    projects?: string[];
    street?: string;
    city?: string;
    zip?: string;
    phone?: string;
    createdAt?: number;
    updatedAt?: number;
    lastEmailVerificationSent?: number;
};

export const getEmptyUser = (uid?: string): UserModel | undefined => {
    return uid
        ? {
            uid,
            displayName: "",
            email: "",
        }
        : undefined;
};

export interface UserDbModel {
    uid: string;
    displayName: string;
    email: string;
    photoUrl?: string;
    street?: string;
    city?: string;
    zip?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    lastEmailVerificationSent?: Timestamp;
    projects: string[];
    createdAt: Timestamp;
    updatedAt: Timestamp;
}

export const toTimestamp = (v: FieldValue | number): FieldValue | Timestamp => {
    if (typeof v === "number") {
        return Timestamp.fromMillis(v);
    }
    return v;
};

const userConverter = {
    toFirestore: (
        value: WithFieldValue<UserModel>,
    ): WithFieldValue<UserDbModel> => {
        console.log({ to_firestore: value });
        const { projects, lastEmailVerificationSent, ...safe } = value;
        return {
            ...safe,
            lastEmailVerificationSent: !!lastEmailVerificationSent
                ? toTimestamp(lastEmailVerificationSent)
                : Timestamp.fromDate(DUMMY_DATE),
            projects: projects ? projects : [],
            createdAt: value.createdAt
                ? toTimestamp(value.createdAt)
                : serverTimestamp(),
            updatedAt: serverTimestamp(),
        };
    },
    fromFirestore: (
        snap: QueryDocumentSnapshot<UserDbModel>,
        options: SnapshotOptions,
    ): UserModel => {
        const { createdAt, updatedAt, lastEmailVerificationSent, ...rest } =
            snap.data(options) as UserDbModel;
        return {
            ...rest,
            createdAt: createdAt?.toMillis(),
            updatedAt: updatedAt && updatedAt.toMillis(),
            lastEmailVerificationSent:
                lastEmailVerificationSent && lastEmailVerificationSent.toMillis(),
        };
    },
};

interface Material {
    user: UserMark;
    name: string;
    amount: number;
    createdAt: Timestamp;
}

export enum WorkType {
    WORK = "work",
    SICKNESS = "sickness",
    VACATION = "vacation",
    LEAVE = "leave",
}

interface Work {
    user: UserMark;
    type: WorkType;
    hours: number;
}

interface ProjectDbModel {
    uid: string;
    owner: DocumentReference;
    materials: Material[];
    work: Work[];
    street?: string;
    city?: string;
    zip?: string;
}

const genericConverter = <T>() => {
    const out = {
        toFirestore: (value: WithFieldValue<T>) => value,
        fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
    };
    return out;
};

const dataPoint = <T>(collectionPath: string) =>
    collection(firestore, collectionPath).withConverter(genericConverter<T>());

export const db = {
    users: collection(firestore, "users").withConverter(userConverter),
    projects: (userId: string) =>
        dataPoint<ProjectDbModel>(`users/${userId}/posts`),
};
