import { createSlice } from "@reduxjs/toolkit";
import { NotificationProps } from "../../components/Notifications/NotificationCenter";

type HeaderState = {
    pageTitle: string;
    noOfNotifications: 15;
    displayNotifications?: NotificationProps;
};

const initialState: HeaderState = {
    pageTitle: "Home", // current page title state management
    noOfNotifications: 15, // no of unread notifications
};

export const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setPageTitle: (state, action: { payload: { title: string } }) => {
            state.pageTitle = action.payload.title;
        },

        showNotification: (state, action: { payload: NotificationProps }) => {
            const notification = action.payload;
            console.log("setting notification");
            state.displayNotifications = notification;
        },

        removeNotification: (state) => {
            delete state.displayNotifications;
        },
    },
});

export const { setPageTitle, showNotification, removeNotification } =
    headerSlice.actions;

export default headerSlice.reducer;
