import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db, makeMark, UserMark } from "../../firestore";

import { getDoc, doc } from "firebase/firestore";

interface UserState {
    user?: UserMark;
    error?: string;
    loading?: boolean;
    success?: boolean;
}

const initialState: UserState = {
    user: undefined,
    // loading info
    error: undefined,
    loading: undefined,
    success: undefined,
};

export const fetchCurrentUser = createAsyncThunk(
    "user/getAuthUser",
    async (uid: string | undefined) => {
        const docRef = await getDoc(doc(db.users, uid));
        if (!docRef.exists()) {
            return null;
        }
        const data = docRef.data();
        return makeMark(data);
    },
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentUser.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                const user = action.payload;
                state.loading = false;
                state.success = true;
                state.user = user || undefined;
            })
            .addCase(fetchCurrentUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default userSlice.reducer;
