import { createSlice } from "@reduxjs/toolkit";
import { ModalTypes } from "../../utils/globalConstantUtil";

interface ModalState {
    message: string;
    isOpen: boolean;
    type: ModalTypes;
    isWarning: boolean;
    extra?: any;
    title?: string;
    size?: string;
}

const initialState: ModalState = {
    title: "",
    message: "",
    size: "md",
    isOpen: false,
    isWarning: false,
    type: ModalTypes.DEFAULT,
};

export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openVerificationModal: (state, action) => {
            const { email, lastVerificationEmailSent } = action.payload;
            console.log("hej");
            console.log({ payload: action.payload });
            state.isOpen = true;
            state.type = ModalTypes.VERIFY_EMAIL;
            state.title = "";
            state.message = email;
            state.isWarning = email.length > 0;
            state.extra = { lastVerificationEmailSent };
        },

        openConfirmationModal: (state, action) => {
            const { title, message, size } = action.payload;
            state.isOpen = true;
            state.type = ModalTypes.CONFIRMATION;
            state.title = title;
            state.message = message;
            state.size = size || "md";
        },

        closeModal: (state, action) => {
            state.isOpen = false;
            state.type = ModalTypes.DEFAULT;
            state.title = "";
            state.message = "";
            state.isWarning = false;
        },
    },
});

export const { openVerificationModal, openConfirmationModal, closeModal } =
    modalSlice.actions;

export default modalSlice.reducer;
