import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth, signOut } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

// if (process.env.REACT_APP_EMULATE) {
if (process.env.REACT_APP_FIREBASE_SIMULATION === "true") {
    console.log("SIMULATION ENVIRONMENT");
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
}
// const db = getDatabase(app);

// TODO redo this:
//  1. Make the call to createUserWithEmailAndPassword in register page
//  2. Make the registration to Cloud Firestore instead of realtime db [ that affects redux as well ]
//  3. Exit early (potentially updating info) if user exists in db already.

const logOut = async () => {
    await signOut(auth);
};

export { auth, firestore, storage, logOut };
