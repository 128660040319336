const initializeApp = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        // dev code
        console.log(` dev ${process.env.REACT_APP_VERSION} `);
        console.log(" localhost ");
    } else {
        // Prod build code
        // Removing console.log from prod
        console.log(` prod ${process.env.REACT_APP_VERSION} `);
        console.log = () => { };
    }
};

export default initializeApp;
