// All components mapping with path for internal routes

import { lazy } from "react";

// const Dashboard = lazy(() => import("../pages/protected/Dashboard"));
const Home = lazy(() => import("../pages/protected/Home"));
const Projects = lazy(() => import("../pages/protected/Projects"));
// const Welcome = lazy(() => import("../pages/protected/Welcome"));
const Page404 = lazy(() => import("../pages/protected/404"));
const Blank = lazy(() => import("../pages/protected/Blank"));
// const Charts = lazy(() => import("../pages/protected/Charts"));
// const Integration = lazy(() => import("../pages/protected/Integration"));
// const Calendar = lazy(() => import("../pages/protected/Calendar"));
// const Team = lazy(() => import("../pages/protected/Team"));
// const Transactions = lazy(() => import("../pages/protected/Transactions"));
// const Bills = lazy(() => import("../pages/protected/Bills"));
const ProfileSettings = lazy(
    () => import("../pages/protected/ProfileSettings"),
);
// const GettingStarted = lazy(() => import("../pages/GettingStarted"));
// const DocFeatures = lazy(() => import("../pages/DocFeatures"));
// const DocComponents = lazy(() => import("../pages/DocComponents"));

interface RouteEntry {
    path: string;
    component: React.LazyExoticComponent<() => JSX.Element>;
}

const routes: RouteEntry[] = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/projects", // the url
        component: Projects, // view rendered
    },
    // {
    //     path: "/hours", // the url
    //     component: Welcome, // view rendered
    // },
    // {
    //     path: "/settings-team",
    //     component: Team,
    // },
    // {
    //     path: "/calendar",
    //     component: Calendar,
    // },
    // {
    //     path: "/transactions",
    //     component: Transactions,
    // },
    {
        path: "/settings-profile",
        component: ProfileSettings,
    },
    // {
    //     path: "/settings-billing",
    //     // component: Bills, # TODO fix billing page
    //     component: Blank,
    // },
    // {
    //     path: "/getting-started",
    //     component: GettingStarted,
    // },
    // {
    //     path: "/features",
    //     component: DocFeatures,
    // },
    // {
    //     path: "/components",
    //     component: DocComponents,
    // },
    // {
    //     path: "/integration",
    //     component: Integration,
    // },
    // {
    //     path: "/charts",
    //     component: Charts,
    // },
    {
        path: "/404",
        component: Page404,
    },
    {
        path: "/blank",
        component: Blank,
    },
];

export default routes;
